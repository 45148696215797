<template>

  <h2>{{orderHistory.length}}</h2>
</template>

<script>
  import {AXIOS} from "../../../http-commons";

  export default {
    name: "HistoryCard",
    data() {
      return {
        orderHistory: [],
      }
    },
    methods: {
      getOrderHistory() {
        let params = new URLSearchParams();
        params.append('orderId', this.$route.params.orderId)
        AXIOS.get('/api/getOrderHistory', {params})
          .then(response => {
            this.orderHistory = response.data;
          })
      },
    },
    created() {
      this.getOrderHistory();
    },
  }
</script>

<style scoped>

</style>
